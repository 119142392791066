.awards_center {
    text-align: justify;
    max-width: 750px;
    margin: auto;
    width: 100%;
    height: 100%
}

iframe {
    height: 400px;
    width: 100%;
}

#recruitTitle {
    color: #00A451;
}

#whereSection {
    text-align: left;
}

#overview {
    font-size: 42px;
}

#addSep {
    border-right: 2px solid #00A451;
}

#noOp p {
    text-align: justify
}

#surveyLink img {
    height: 200px;
    width: 180px;
}

#surveyLink p {
    color: #00A451;
    font-size: 2em;
    text-align: left;
    font-weight: bold;
}

.iconDrop:hover {
    color: rgb(193, 255, 23);
}