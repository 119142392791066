#newsIntro {
    font-size: 20px;
    width: 60%;
    margin: auto;
    text-align: justify;
}

#newsTitle {
    font-weight: bold;
    text-align: left;
    color: rgba(0, 164, 81, 1);
    overflow: hidden;
}

#newsTitle:after {
    background-color: #00A451;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 100%;
}

#newsTitle:after {
    left: 0.5em;
    margin-right: -50%;
}

#newsTitle2 {
    color: gray;
    text-align: left;

}



#newsLink {
    font-size: 20px;
    font-weight: bold;
}

#newsImg {
    max-width: 374px;
    width: 100%;
    height: 100%;
}

#openingRecep {
    text-align: left;
    color: rgba(0, 164, 81, 1);
    font-size: 40px;
}

#openingPara {
    text-align: justify;
}

img {
    width: 100%;
    height: 100%;
}

#kxtry {
    margin-top: -25px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(0, 164, 81, 0.7);
    /* background-color: #00A451; */
    /* opacity: .5; */
}

#noOp {
    /* font-weight: bold; */
    color: #FFFFFF;
    text-decoration: none;
}

#cardLink {
    text-decoration: none;
}

#subscribeYeah {
    background: rgba(0, 164, 81, 0.7);
    color: #FFFFFF;
}

.full-width {
    width: 100%;
    /* padding-right: 200px;
    padding-left: 200px;
    padding-bottom: 70px !important; */
}

#subscribeYeah h1 {
    overflow: hidden;
    text-align: center;
    color: #FFFFFF;
}

#subscribeYeah h1:before,
#subscribeYeah h1:after {
    background-color: #FFFFFF;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 30%;
}