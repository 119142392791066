/* .nav-bar img {
    padding-left: 22px !important;
} */

.link {
    margin-top: 38px;
    height: 30px;
    flex: 1;
    white-space: nowrap;
    /* overflow: hidden; */
}

/* h5 {
    background-color: #00A451;
} */

.vertical-row {
    Float: left;
    margin-top: 38px;
    height: 30px;
    width: 3px;
    /* edit this if you want */
    background-color: #00A451
}

.vertical-row-end {
    Float: left;
    margin-top: 38px;
    margin-right: 15px;
    height: 30px;
    width: 3px;
    /* edit this if you want */
    background-color: #00A451
}

.flex-grow-1 {
    position: relative;
}

.active-topbar {
    background-color: #00A451;
    height: 39px;
}

.link h5 {
    margin-top: -6px;
}

.active-topbar h5 {
    color: white;
    margin-top: 0px;
}

.bg-custom {
    background-color: #00A451;
}

.navbar-toggler {
    margin-right: 10px;
}

/* #biglogo {
    position: absolute;

}

.topbar {
    background-color: rgb(192, 192, 192) !important;
} */

/* @media only screen and (min-width: 992px) {} */