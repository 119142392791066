#product {
    font-size: 42px;
}

#productText {
    text-align: left;
    color: black;
}

#productContainer {
    color: #00A451;
}

#kxtry2 .card-title {
    font-size: 2.5rem;
}

#kxtry2 {
    margin-top: -65px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(0, 164, 81, 0.7);
    /* height: 100%;
    flex: 1 */
    /* background-color: #00A451; */
    /* opacity: .5; */
}

#calborder {
    color: #00A451;
    border-style: solid;
    border-color: #00A451;
    border-width: 1px;
}

#secondBorder {
    color: #00A451;
    border-style: solid;
    border-color: rgba(0, 164, 81, 0.5);
    border-width: 1px;
    text-align: left;
    font-weight: bold;
    position: relative;
}

#secondBorder p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}

#firstField {
    text-align: left;
}

.radio-btn-container {
    display: flex;
    font-weight: bold;
}

.radio-btn-container input {
    accent-color: #00A451;
}

.radio-btn {
    margin-left: 3em;
}

.title-select.activeTab {
    background-color: rgba(0, 164, 81, 0.9);
    color: white;
    border-top-right-radius: 30px;
}

.title-select.inactiveTab {
    background-color: white;
}

.title-select.inactiveTab:hover {
    background-color: rgba(0, 164, 81, 0.7);
    color: white;
    border-top-right-radius: 30px;
}

.prod-title {
    font-size: 2em;
    display: inline;
    font-weight: bolder;
}

.cal-unit {
    float: right;
}

.final-price {
    font-size: 1.5em;
    position: relative;
}

.final-price p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}

#specialPlaceholder {
    height: 2em;
}

.prod-attachments {
    text-align: left;
}

.prod-attachments a {
    width: 70%;
    color: #00A451;
    font-weight: bold;
    padding-left: 15%;
    text-decoration: none;
}