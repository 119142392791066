.AIfooter img {
    height: 40px;
    width: 40px;
}

.AIfooter {
    /* position: absolute; */
    right: 0
}

#footernav {
    background-color: rgb(32, 31, 31)
}