@import "~react-image-gallery/styles/css/image-gallery.css";

.vertical-row-norm {
    Float: left;
    height: 30px;
    width: 3px;
    /* edit this if you want */
    background-color: #00A451
}

.link-norm {
    height: 30px;
    flex: 1;
    white-space: nowrap;
    /* overflow: hidden; */
}

.link-norm h5 {
    margin-top: -6px;
}

.active-norm {
    color: black !important;
}

.project-slideshow {
    max-height: 75vh !important;
    height: auto !important;
    width: 100% !important;
}

.description-slideshow {
    background: rgba(0, 164, 81, 0.7) !important;
    bottom: 0px !important;
    left: 0 !important;
    /* height: 30% !important; */
    width: 100% !important;
}

.long-description-slideshow {
    background: #00A451;
    color: white;
    white-space: normal;
    line-height: 1 !important;
    /* word-wrap: break-word; */
}