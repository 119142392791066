.recruit_left {
    padding-right: 15px;
    text-align: left;
}

.recruit_right {
    text-align: left;
}

.recruitCard {
    text-align: left;
}

.titleRecruit {
    color: #039de6;
}

.titleRecruit:hover {
    text-decoration: underline
}

.smallerSpan {
    font-size: x-small;
}

#exchangePhoto {
    width: 100%;
    height: 100%;
    max-width: 350px;
}

#recruitContainer {
    color: #00A451;
}

.hihi {
    color: black;
}

#recruitText {
    text-align: left;
    color: black;

}

#exchangeText {
    text-align: left;
    color: black;
}

#exchangeText p {
    justify-content: center;
    align-items: center;
}


h3 {
    overflow: hidden;
    text-align: center;
    color: #00A451;
}

h3:before,
h3:after {
    background-color: #00A451;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

h3:before {
    right: 0.5em;
    margin-left: -50%;
}

h3:after {
    left: 0.5em;
    margin-right: -50%;
}

#recruitment {
    font-size: 42px;
}