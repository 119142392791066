/* .team__gallery img {
    /* display: block;
    margin: auto;
    border-radius: 50%;
    padding-bottom: 10px; */
/* width: 100%;
    height: 100%;
    max-width: 400px; */


#lpara {
    text-align: left;
    color: rgb(75, 73, 73);
}

.team__gallery div {
    margin: 20px 0;
    text-align: center;
    font-size: 18px;
}

h1 {
    overflow: hidden;
    text-align: center;
    color: #00A451;
}

h1:before,
h1:after {
    background-color: #00A451;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

h1:before {
    right: 0.5em;
    margin-left: -50%;
}

h1:after {
    left: 0.5em;
    margin-right: -50%;
}

#wilsonPic img {
    width: 100%;
    height: 100%
}

#wilsonPic h2 {
    text-align: left;
}

#helloxcxcx img {
    width: 70%;
    height: 80%;
}

#helloxcxcx p {
    color: rgb(75, 73, 73)
}

/* #wilsonPic {
    color: gray !important;
} */